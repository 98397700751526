import { EmptyState } from "../empty-state";
import { NiloMobilePagination } from "../mobile-pagination";
import { NiloPagination } from "../pagination";
import { NiloTableWrapper } from "./nilo-table-wrapper";
import { NiloTableHead } from "./nilo-tablehead";
import { OrderByParameters } from "./utils";

type DataEmpty = {
  isEmpty: boolean;
  message: string;
};

export interface NiloDatatableProps<T> {
  isLoading?: boolean;
  mapOrderBy?: (criteria) => string;
  tableHead?: string[];
  columnsAbleToSort?: string[];
  children?: React.ReactNode;
  dataEmpty?: DataEmpty;
  handleOrderBy?: (value: OrderByParameters<T>) => void;
  take?: number;
  orderBy?: OrderByParameters<T>;
  pageCount?: number;
  handlePage?: (value) => void;
  columnsToHideResponsive?: string[];
  emptyStateSize?: "xs" | "sm" | "md" | "lg";
  isMobileTable?: boolean;
  forcePage?: number;
}

export const NiloDatatable = <T,>({
  children,
  columnsAbleToSort,
  dataEmpty,
  handleOrderBy,
  handlePage,
  isLoading,
  columnsToHideResponsive,
  mapOrderBy,
  orderBy,
  pageCount,
  tableHead,
  take,
  emptyStateSize,
  isMobileTable,
  forcePage
}: NiloDatatableProps<T>) => {
  return (
    <>
      {dataEmpty?.isEmpty && !isLoading ? (
        <div className="py-4">
          <EmptyState
            description={dataEmpty.message}
            size={emptyStateSize || "sm"}
          />
        </div>
      ) : (
        <>
          <NiloTableWrapper isLoading={isLoading}>
            <NiloTableHead
              handleOrderBy={handleOrderBy}
              mapOrderBy={mapOrderBy}
              orderBy={orderBy}
              tableHead={tableHead}
              columnsAbleToSort={columnsAbleToSort}
              columnsToHideResponsive={columnsToHideResponsive}
            />
            {children}
          </NiloTableWrapper>
          <div className="mt-8 flex items-center justify-center">
            {isMobileTable ? (
              <NiloMobilePagination
                count={pageCount || 1}
                handlerPage={handlePage}
                take={take}
              />
            ) : (
              <NiloPagination
                count={pageCount || 1}
                handlerPage={handlePage}
                take={take}
                forcePage={forcePage}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
