import { FC, ReactNode } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";
import { NiloButton, typeButton } from "./button";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  title?: string;
  children: ReactNode;
  submitLabel: string;
  submitHandler: () => void;
  open: boolean;
  openHandler: () => void;
  disableSubmit?: boolean;
}

export const NiloDialog: FC<Props> = ({
  children,
  disableSubmit,
  open,
  openHandler,
  submitHandler,
  submitLabel,
  title
}) => {
  return (
    <div>
      <Dialog size="md" open={open} handler={() => {}}>
        <DialogHeader className="justify-between">
          <div className="text-base font-medium text-slate-900">{title}</div>
          <XMarkIcon className="h-6 cursor-pointer" onClick={openHandler} />
        </DialogHeader>
        <DialogBody className="overflow-y-hidden !px-5 py-2">
          {children}
        </DialogBody>
        <DialogFooter className="justify-between gap-2">
          <div className="flex w-full justify-end">
            <NiloButton
              variant="link"
              onClick={openHandler}
              className="me-2 px-14 font-semibold"
            >
              Cancelar
            </NiloButton>
            <NiloButton
              onClick={submitHandler}
              type={typeButton.button}
              disabled={disableSubmit}
              className="px-14 md:py-3"
            >
              {submitLabel}
            </NiloButton>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
};
