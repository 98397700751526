import { DayPicker } from "react-day-picker";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import classNames from "./datepicker-classnames.json";

interface Props {
  date: Date;
  handleDateChange: (selectedDate: Date) => void;
}

export const Datepicker = ({ date, handleDateChange }: Props) => {
  return (
    <DayPicker
      mode="single"
      selected={date}
      onSelect={handleDateChange}
      showOutsideDays
      className="border-0"
      classNames={classNames}
      components={{
        IconLeft: ({ ...props }) => (
          <ChevronLeftIcon {...props} className="h-4 w-4 stroke-2" />
        ),
        IconRight: ({ ...props }) => (
          <ChevronRightIcon {...props} className="h-4 w-4 stroke-2" />
        )
      }}
    />
  );
};
