import { FC } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import ReactPaginate from "react-paginate";

interface Props {
  take?: number;
  count: number;
  handlerPage: (page: number) => void;
  initialPage?: number;
}

export const TAKE_DEFAULT = 8;

export const NiloMobilePagination: FC<Props> = ({
  take = TAKE_DEFAULT,
  count,
  handlerPage,
  initialPage
}) => {
  const pageCount = count > take ? Math.ceil(count / take) : 1;

  const onChangePage = ({ selected }) => {
    handlerPage(selected + 1);
  };

  return (
    <>
      <div
        className={`items-center gap-4 ${pageCount > 1 ? "flex" : "hidden"}`}
      >
        <ReactPaginate
          className="font-regular flex items-center gap-8 text-sm"
          breakLabel="..."
          onPageChange={onChangePage}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          activeClassName="rounded-lg bg-slate-900 text-white px-4 py-2"
          disabledClassName="[&>*]:!cursor-not-allowed"
          previousLabel={
            <div className="flex items-center font-bold">
              <ChevronLeftIcon strokeWidth={2} className="size-5" />
              <span className="pl-2">Anterior</span>
            </div>
          }
          nextLabel={
            <div className="flex items-center font-bold">
              <span className="pr-2">Siguiente</span>
              <ChevronRightIcon strokeWidth={2} className="size-5" />
            </div>
          }
          renderOnZeroPageCount={null}
          disabledLinkClassName="text-slate-400 cursor-none"
          initialPage={initialPage}
        />
      </div>
    </>
  );
};
