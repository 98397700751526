import * as messagesEs from "@i18n-messages/es.json";
import * as messagesEn from "@i18n-messages/en.json";
import * as messagesEsHN from "@i18n-messages/es-HN.json";
import * as messagesEsAR from "@i18n-messages/es-AR.json";

import { localesType, lang } from "../constants";
import { AbstractIntlMessages } from "next-intl";
import { deepMerge } from "../utils/merge";

export const messages = {
    [lang.es]: messagesEs,
    [lang.en]: messagesEn,
    [lang.esHN]: deepMerge({ ...messagesEs }, messagesEsHN),
    [lang.esAR]: deepMerge({ ...messagesEs }, messagesEsAR)

}

export const getMessages = function (locale: localesType) {
    return messages[locale] as unknown as AbstractIntlMessages;
}

