/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorMessage, useField } from "formik";
import { FC, useState } from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import { EyeSlashIcon } from "@heroicons/react/24/outline";

export enum typeInput {
  TEXT = "text",
  PASSWORD = "password",
  email = "email"
}

interface Props {
  name: string;
  label: string;
  type?: typeInput;
  placeholder?: string;
  onlyNumber?: boolean;
  containerClassname?: string;
  classInput?: string;
  hideError?: boolean;
  disabled?: boolean;
  [x: string]: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  Icon?: any;
}

export const NiloInput: FC<Props> = ({
  Icon,
  classInput,
  containerClassname,
  disabled = false,
  hideError = false,
  label,
  maxLength,
  onBlur,
  onChange,
  onlyNumber = false,
  type = typeInput.TEXT,
  ...props
}) => {
  const [field] = useField(props);

  const [state, setState] = useState({
    showPassword: false
  });

  const togglePasswordVisibility = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleInputNumericChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value) || value === "") emitOnChangeEvent(e);
  };

  const emitOnChangeEvent = (e) => {
    field.onChange(e);
    !!onChange && onChange(e);
  };

  const emitOnBlurEvent = (e) => {
    field.onBlur(e);
    !!onBlur && onBlur(e);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onlyNumber ? handleInputNumericChange(e) : emitOnChangeEvent(e);
  };

  const isPassword = type === typeInput.PASSWORD;

  const getType = () => {
    return isPassword
      ? state.showPassword
        ? typeInput.TEXT
        : typeInput.PASSWORD
      : type;
  };

  const RenderPasswordVisibility = () => {
    return (
      <div className="absolute right-0 top-3 flex items-center text-sm leading-5">
        <button
          type="button"
          className="cursor-pointer px-4"
          onClick={togglePasswordVisibility}
        >
          {state.showPassword ? (
            <EyeIcon className={"h-6"} />
          ) : (
            <EyeSlashIcon className={"h-6"} />
          )}
        </button>
      </div>
    );
  };

  return (
    <div className={containerClassname}>
      {!!label && (
        <label
          className="mb-2 block text-xs text-slate-900"
          htmlFor={props.name}
        >
          {label}
        </label>
      )}
      <div className={`flex ${isPassword || !!Icon ? "relative" : ""}`}>
        <input
          id={props.name}
          {...field}
          {...props}
          type={getType()}
          data-testid={`${props.name}-input`}
          className={`w-full rounded-lg p-3 text-sm placeholder-slate-400 focus:outline-none ${
            disabled
              ? "pointer-events-none bg-slate-200 text-slate-400"
              : "text-slate-900"
          } autofill:bg-white ${classInput} `}
          onChange={handleInputChange}
          onBlur={emitOnBlurEvent}
          maxLength={maxLength}
        />
        {isPassword && <RenderPasswordVisibility />}
        {!!Icon && <div className="absolute bottom-0 right-0 p-2">{Icon}</div>}
      </div>

      {!hideError && (
        <ErrorMessage
          name={props.name}
          component="div"
          className="mt-2 text-xs text-red-600"
        />
      )}
    </div>
  );
};
