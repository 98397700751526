export class UsernameParser {
  private username: string;

  constructor(username: string) {
    this.username = username;
  }

  getSubdomain(): string | undefined {
    const parts = this.username.split("#");
    return parts[0];
  }

  getPlatform(): string | undefined {
    const parts = this.username.split("#");
    return parts[1];
  }

  getLoginMethod(): string | undefined {
    const parts = this.username.split("#");
    return parts[2];
  }

  getEmailOrPhone(): string | undefined {
    const parts = this.username.split("#");
    return parts[3];
  }
}
