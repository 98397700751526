import { getLocale } from "../utils";

export const redirectNoAuth = `/${getLocale()}/login`;

export const publicPaths = [
  /^\/$/,
  /^\/(es|en|es-HN|es-AR)$/,
  /^\/(es|en|es-HN|es-AR)\/register$/,
  /^\/(es|en|es-HN|es-AR)\/login(\/.*)?$/
];
