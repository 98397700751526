import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon
} from "@heroicons/react/24/outline";
import { OrderByParameters } from "./utils";

interface TableHeaderProps<T> {
  head?: string;
  isSelected?: boolean;
  canBeSorted?: boolean;
  onClick?: () => void;
  orderBy?: OrderByParameters<T>;
  columnsToHideResponsive?: string[];
}

export const NiloTableHeader = <T,>({
  canBeSorted,
  head,
  isSelected,
  columnsToHideResponsive,
  onClick,
  orderBy
}: TableHeaderProps<T>) => {
  return (
    <th
      className={`bg-slate-50 p-4 text-xs font-normal text-slate-400 ${columnsToHideResponsive?.includes(head) ? "hidden md:table-cell" : ""} ${
        canBeSorted ? "cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex items-center justify-start">
        {head}
        {isSelected &&
          (orderBy?.order === "asc" ? (
            <ChevronUpIcon className="ml-2 h-4" />
          ) : (
            <ChevronDownIcon className="ml-2 h-4" />
          ))}
        {!isSelected && canBeSorted && (
          <ChevronUpDownIcon className="ml-2 h-4" />
        )}
      </div>
    </th>
  );
};
