import {
  XCircleIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon
} from "@heroicons/react/24/outline";
import { FC } from "react";

export enum MessageType {
  ERROR = "error",
  WARNNING = "warnning",
  SUCCESS = "success"
}

interface Props {
  message: string;
  type?: MessageType;
  position?: "static" | "bottom";
  containerClassName?: string;
}

export const NiloAlert: FC<Props> = ({
  containerClassName,
  message,
  position = "bottom",
  type = MessageType.ERROR
}) => {
  const typeClasses = {
    [MessageType.ERROR]: "bg-red-600",
    [MessageType.SUCCESS]: "bg-green-600",
    [MessageType.WARNNING]: "bg-orange-600"
  };

  const typeIcon = {
    [MessageType.ERROR]: <XCircleIcon className="me-3 h-6 w-6" />,
    [MessageType.SUCCESS]: <CheckCircleIcon className="me-3 h-6 w-6" />,
    [MessageType.WARNNING]: <ExclamationTriangleIcon className="me-3 h-6 w-6" />
  };

  const classPosition = {
    ["bottom"]: "fixed bottom-10 right-0 left-0 max-w-[328px] mx-auto",
    ["static"]: ""
  };

  return (
    <div className={`${classPosition[position]} ${containerClassName}`}>
      <div
        data-test-id={`alert-message-${type}`}
        className={`font-regular relative flex w-full items-center rounded-lg p-3 pe-2 text-xs text-white ${typeClasses[type]} `}
      >
        {typeIcon[type]}
        <>{message}</>
      </div>
    </div>
  );
};
