import { NiloTableHeader } from "./nilo-tableheader";
import { OrderByParameters, sortColumn } from "./utils";

interface TableHeadProps<T> {
  handleOrderBy?: (value) => void;
  mapOrderBy?: (criteria) => string;
  tableHead?: string[];
  columnsAbleToSort?: string[];
  columnsToHideResponsive?: string[];
  orderBy?: OrderByParameters<T>;
}

export const NiloTableHead = <T,>({
  columnsAbleToSort,
  columnsToHideResponsive,
  handleOrderBy,
  mapOrderBy,
  orderBy,
  tableHead
}: TableHeadProps<T>) => {
  return (
    <thead>
      <tr>
        {tableHead.map((head, index) => {
          const isSelected =
            mapOrderBy && orderBy?.criteria === mapOrderBy(head);

          const canBeSorted =
            columnsAbleToSort && columnsAbleToSort.includes(head);

          const handleSort = () => {
            mapOrderBy &&
              canBeSorted &&
              sortColumn({
                canBeSorted,
                isSelected,
                criteria: mapOrderBy(head),
                currentOrder: orderBy?.order,
                handleOrderBy
              });
          };

          return (
            <NiloTableHeader
              key={index}
              head={head}
              orderBy={orderBy}
              columnsToHideResponsive={columnsToHideResponsive}
              canBeSorted={canBeSorted}
              isSelected={isSelected}
              onClick={handleSort}
            />
          );
        })}
      </tr>
    </thead>
  );
};
