"use client";

import { Money } from "@nilo/codegen";
import { toLocalizedMoneyFormat } from "@nilo/money";
import { getLocale } from "../i18n/utils/index";

interface Props {
  money: Money;
}

export const fomattedMoney = (money: Money) => {
  if (!money) return "";
  const locale = getLocale();
  const formatted = toLocalizedMoneyFormat(money, locale);
  return formatted.text;
};

export const NiloMoney = ({ money }: Props) => {
  const formatted = fomattedMoney(money);
  return <>{formatted}</>;
};
