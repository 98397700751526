import { Currency, Dinero } from "dinero.js";
import DineroFactory from "dinero.js";

import {
  CartSubtotals,
  Money,
  MoneyFormat,
  Promotion,
  ProductInCart,
  DiscountTier,
  CalculateBonusChallengeResponse,
  CalculateDiscountChallengeResponse,
  BonusPromotionData,
  DiscountPromotionData,
  BonusIndividualPromotion,
  BonusCrossedPromotion,
  ProductSubtotal,
  RewardedProductData,
  AppliedDiscount,
  ProductInPromotion,
  DisplayProductInCartData
} from "./money.model";

/*
  HALF_UP: half-way values of x are always rounded up
  Ex: 1.5 -> 2, 1.4 -> 1, 1.6 -> 2
*/
const DEFAULT_ROUNDING_MODE = "HALF_UP";

const DEFAULT_CURRENCY_BASE = 10;
const DEFAULT_CURRENCY_EXPONENT = 2;
const DEFAULT_SCALE = 2;
const DEFAULT_LOCALE = "es-AR";
const DEFAULT_FORMAT = "$0,0.00";

export function floatToDinero({
  amount,
  currency = "ARS",
  scale
}: {
  amount: number;
  currency?: string;
  scale?: number;
}): Dinero {
  const factor = DEFAULT_CURRENCY_BASE ** (scale || DEFAULT_CURRENCY_EXPONENT);
  const roundedAmount = Math.round(amount * factor);

  return DineroFactory({
    amount: roundedAmount,
    currency: currency as Currency,
    precision: scale || DEFAULT_SCALE
  });
}

export function toMoneyFormat(
  amount: number,
  currency: string,
  scale: number,
  format?: string
): MoneyFormat {
  //TODO: tomar el ROUNDING_MODE de la config del supplier
  //TODO: agregar el setLocale segun el idioma del supplier
  const d = DineroFactory({
    amount,
    currency: currency as Currency,
    precision: scale
  });

  return {
    value: d.toRoundedUnit(DEFAULT_SCALE, DEFAULT_ROUNDING_MODE),
    text: format
      ? d.toFormat(format, DEFAULT_ROUNDING_MODE)
      : d
          .setLocale(DEFAULT_LOCALE)
          .toFormat(DEFAULT_FORMAT, DEFAULT_ROUNDING_MODE)
  };
}

export function toLocalizedMoneyFormat(
  money: Money,
  locale?: string
): MoneyFormat {
  const d = DineroFactory({
    amount: money.amount,
    currency: money.currency as Currency,
    precision: money.scale
  });
  return {
    value: d.toRoundedUnit(DEFAULT_SCALE, DEFAULT_ROUNDING_MODE), //add scale and rounding mode to supplier settings
    text: d
      .setLocale(locale || DEFAULT_LOCALE)
      .toFormat(DEFAULT_FORMAT, DEFAULT_ROUNDING_MODE) //add format to supplier settings
  };
}

export function addFormattedToMoney(
  money: Money
): Money & { formatted: MoneyFormat } {
  return {
    ...money,
    formatted: toMoneyFormat(money.amount, money.currency, money.scale)
  };
}

export function moneyToDinero({ amount, currency, scale }: Money): Dinero {
  return DineroFactory({
    amount,
    currency: currency as Currency,
    precision: scale
  });
}

export function floatToMoney({
  amount,
  currency = "ARS",
  scale
}: {
  amount: number;
  currency?: string;
  scale?: number;
}): Money {
  return toObject(
    floatToDinero({
      amount,
      currency,
      scale
    })
  );
}

// export function splitPaymentStructure({}) {}

//Equivalent to DINERO TO MONEY
export function toObject(dinero: Dinero, formatted?: boolean): Money {
  const dineroObject = dinero.toObject();
  return {
    amount: dineroObject.amount,
    currency: dineroObject.currency,
    scale: dineroObject.precision,
    ...(formatted && {
      formatted: toMoneyFormat(
        dineroObject.amount,
        dineroObject.currency,
        dineroObject.precision
      )
    })
  };
}

/*
  Given a list of products and a drop size,
  calculate the subtotals of each product,
  the total price of the products,
  and the remaining amount to reach the drop size.
  Returns an object with the subtotals, the drop size status and the total price.
*/

export function calculateCart(
  displayProductsInCart: ProductInCart[],
  dropSize: Money
): CartSubtotals {
  const uniquePromotionsInCart: Promotion[] = [];
  const rewardedProducts: RewardedProductData[] = [];

  // get all unique promos from my products
  displayProductsInCart.forEach((prod) => {
    prod.promotions.forEach((promotion) => {
      const promotionAlreadyExist = uniquePromotionsInCart.some(
        (prom) => prom.id === promotion.id
      );
      if (!promotionAlreadyExist) {
        uniquePromotionsInCart.push(promotion);
      }
    });
  });

  uniquePromotionsInCart.forEach((currentPromo) => {
    const productsInCurrentPromotion = currentPromo.products;
    const productIdsOfPromotion = productsInCurrentPromotion.map((p) => p.id);
    const displayProductsInCartData: DisplayProductInCartData[] =
      displayProductsInCart
        .filter((p) =>
          currentPromo.products.some((prod) => prod.id === p.product.id)
        )
        .map((p) => ({
          displayProductId: p.id,
          units: p.units,
          quantity: p.quantity,
          productId: p.product.id,
          boxRatio: currentPromo.products.find((pr) => pr.id === p.product.id)!
            .boxRatio
        }));

    const isBonus = promotionIsBonus(currentPromo);
    if (isBonus) {
      const challenge = calculateChallengeForBonusPromotion({
        promotionData: {
          productIds: productIdsOfPromotion,
          bonusTier: currentPromo.bonusTier
        },
        displayProductsInCartData
      });
      const isApplicable = !!challenge.accumulatedRewards;

      if (!isApplicable) return;

      rewardedProducts.push({
        promotionId: currentPromo.id,
        rewardedProductDetails: currentPromo.bonusTier.rewardedProductDetails,
        accumulatedProductsRewarded: challenge.accumulatedProductsRewarded,
        accumulatedRewards: challenge.accumulatedRewards,
        requiredProducts: currentPromo.products
      });
    } else {
      const challenge = calculateChallengeForDiscountPromotion({
        promotionData: {
          productIds: productIdsOfPromotion,
          discountTiers: currentPromo.discountTiers
        },
        displayProductsInCartData
      });

      const isApplicable = !!challenge.currentTier;
      if (!isApplicable) return;

      // TODO: function apply max stock for promotion

      displayProductsInCart.forEach((display) => {
        if (productIdsOfPromotion.includes(display.product.id)) {
          const displaySubtotal = multiply(display.price, display.quantity);

          const finalPriceDisplay = percentage(
            displaySubtotal,
            100 - challenge.currentTier!.discountPercentage
          );

          display.appliedDiscount = {
            total: subtract(displaySubtotal, finalPriceDisplay),
            percentage: challenge.currentTier!.discountPercentage,
            promotionId: currentPromo.id,
            requiredProducts: currentPromo.products,
            tier: challenge.currentTier!
          };
        }
      });
    }
  });

  const groupedProductsByDiscount = displayProductsInCart.reduce(
    (acc, curr) => {
      if (curr.appliedDiscount) {
        const position = acc.find(
          (item) => item.promotionId === curr.appliedDiscount.promotionId
        );
        if (position) {
          position.displays.push(curr);
        } else {
          acc.push({
            promotionId: curr.appliedDiscount.promotionId,
            displays: [curr],
            discountPercentage: curr.appliedDiscount.percentage,
            tier: curr.appliedDiscount.tier,
            requiredProducts: curr.appliedDiscount.requiredProducts
          });
        }
      }
      return acc;
    },
    [] as Array<{
      promotionId: number;
      displays: ProductInCart[];
      discountPercentage: number;
      requiredProducts: ProductInPromotion[];
      tier: DiscountTier;
    }>
  );

  // Agrupo los displays por producto. Hago los P*Q de cada display (redondeo)
  // Sumo los resultados (redondeo)
  // Calculo el descuento de la promo para el producto (redondeo) y resto al subtotal.
  // Sumo los totales de los productos.

  const productWithDiscountSubtotals: (AppliedDiscount & {
    totalGross: Money;
    totalWithDiscountsApplied: Money;
  })[] = groupedProductsByDiscount.map((item) => {
    const totalGross = calculateFinalPrice(item.displays);
    const totalWithDiscountsApplied = calculatePromotionTotalFromDisplays(
      item.displays,
      100 - item.discountPercentage
    );
    const discountedAmount = subtract(totalGross, totalWithDiscountsApplied);

    return {
      promotionId: item.promotionId,
      requiredProducts: item.requiredProducts,
      totalGross: addFormattedToMoney(totalGross),
      totalWithDiscountsApplied: addFormattedToMoney(totalWithDiscountsApplied),
      tier: item.tier,
      amount: discountedAmount
    };
  });

  const subtotals: ProductSubtotal[] = displayProductsInCart.map((product) => {
    return {
      displayProductId: product.id,
      subtotal: getSubtotal(product),
      appliedDiscount: product.appliedDiscount
    };
  });

  const orderSubtotal = calculateFinalPrice(displayProductsInCart);
  const totalAppliedDiscount = productWithDiscountSubtotals.length
    ? addMoneyObjects(...productWithDiscountSubtotals.map((p) => p.amount))
    : { amount: 0, scale: 2, currency: orderSubtotal.currency }; // TODO: take currency from supplier settings

  const orderTotal = subtract(orderSubtotal, totalAppliedDiscount);

  const remainingForDropSize = moneyToDinero(subtract(dropSize, orderTotal));

  return {
    appliedDiscounts: productWithDiscountSubtotals,
    productSubtotals: subtotals,
    dropsize: {
      isReached:
        remainingForDropSize.isZero() || remainingForDropSize.isNegative(),
      remaining: toObject(remainingForDropSize, true)
    },
    rewardedProducts,
    subtotal: addFormattedToMoney(orderSubtotal),
    totalAppliedDiscount: addFormattedToMoney(totalAppliedDiscount),
    total: addFormattedToMoney(orderTotal)
  };
}

export function calculateChallengeForDiscountPromotion({
  displayProductsInCartData,
  promotionData
}: {
  promotionData: DiscountPromotionData;
  displayProductsInCartData: DisplayProductInCartData[];
}): CalculateDiscountChallengeResponse {
  let accumulatedBoxes = 0;
  let currentTier: DiscountTier | undefined;
  let nextTier: DiscountTier = promotionData.discountTiers[0];
  const reachedTiers: DiscountTier[] = [];

  // TODO: cash challenges

  displayProductsInCartData.forEach((prod) => {
    if (promotionData.productIds.includes(prod.productId)) {
      const boxes = (prod.units / prod.boxRatio) * prod.quantity;

      accumulatedBoxes += boxes;
    }
  });

  // todo: fijarse si vienen sorted
  promotionData.discountTiers.forEach((tier, i) => {
    if (accumulatedBoxes >= tier.min) {
      // This is required for non-continuous tiers
      // if (!tier.max || accumulatedBoxes <= tier.max) {
      // }
      currentTier = tier;
      nextTier = promotionData.discountTiers[i + 1];
      reachedTiers.push(tier);
    }
  });

  return {
    currentBoxes: Math.floor(accumulatedBoxes),
    remainingBoxes: Math.ceil(nextTier ? nextTier.min - accumulatedBoxes : 0),
    currentTier,
    nextTier,
    reachedTiers
  };
}

export function calculateChallengeForBonusPromotion({
  displayProductsInCartData,
  promotionData
}: {
  promotionData: BonusPromotionData;
  displayProductsInCartData: DisplayProductInCartData[];
}): CalculateBonusChallengeResponse {
  let accumulatedBoxes = 0;

  // TODO: cash challenges

  displayProductsInCartData.forEach((prod) => {
    if (promotionData.productIds.includes(prod.productId)) {
      const boxes = (prod.units / prod.boxRatio) * prod.quantity;

      accumulatedBoxes += boxes;
    }
  });

  // Currently for BONUS type we have only one tier

  const requiredBoxesQuantity = promotionData.bonusTier.quantityRequired;
  const rewardedBoxesQuantity =
    promotionData.bonusTier.rewardedProductDetails.rewardedQuantity;

  const accumulatedRewards = Math.floor(
    accumulatedBoxes / requiredBoxesQuantity
  );
  const remainder = (accumulatedBoxes / requiredBoxesQuantity) % 1;

  const accumulatedProductsRewarded =
    accumulatedRewards * rewardedBoxesQuantity;

  const remainingForNextReward = Math.ceil(
    (1 - remainder) * requiredBoxesQuantity
  );
  return {
    currentBoxes: Math.floor(accumulatedBoxes),
    accumulatedRewards,
    accumulatedProductsRewarded,
    remainingForNextReward
  };
}

/*
  Given a list of products, calculate the final price
  by calculating the subtotal of each product and adding them all together
  Returns the final price as a Dinero object
*/
export function calculateFinalPrice(
  displayProducts: Omit<ProductInCart, "product">[]
): Money {
  let finalPrice: Dinero;
  for (const displayProduct of displayProducts) {
    const priceAsDinero = moneyToDinero({
      amount: displayProduct.price.amount,
      currency: displayProduct.price.currency,
      scale: displayProduct.price.scale
    });
    const subtotalAsDinero = priceAsDinero.multiply(displayProduct.quantity);
    finalPrice = finalPrice
      ? finalPrice.add(subtotalAsDinero)
      : subtotalAsDinero;
  }

  return toObject(finalPrice);
}

/*
  Given a product, calculate the subtotal
  by multiplying the price by the quantity.
  Returns the subtotal as a Money object
*/
export function getSubtotal(productInCart: ProductInCart): Money {
  const result = moneyToDinero(productInCart.price).multiply(
    productInCart.quantity
  );
  // if (productInCart.appliedDiscount) {
  //   result = result.subtract(moneyToDinero(productInCart.appliedDiscount));
  // }
  return toObject(result, true);
}

function promotionIsBonus(
  promo: Promotion
): promo is BonusIndividualPromotion | BonusCrossedPromotion {
  return "bonusTier" in promo && promo.bonusTier !== null;
}

/*
  Given a price and a number of units, calculate the unit price
  by dividing the price by the number of units.
  Returns the unit price as a Money object
*/
export function calculateUnitPrice(units: number, price: Money): Money {
  const { amount, currency, scale } = price;

  const totalPrice = DineroFactory({
    amount,
    precision: scale,
    currency: currency as Currency
  });
  const unitPrice = totalPrice.divide(units, DEFAULT_ROUNDING_MODE);

  return toObject(unitPrice);
}

export function subtractCredit(credit: Money, total: Money): Money {
  const diff = moneyToDinero(total).subtract(moneyToDinero(credit));
  return toObject(diff, true);
}

export function subtract(a: Money, b: Money): Money {
  return toObject(moneyToDinero(a).subtract(moneyToDinero(b)));
}

export function greaterThanOrEqual(a: Money, b: Money): boolean {
  return moneyToDinero(a).greaterThanOrEqual(moneyToDinero(b));
}

export function greaterThan(a: Money, b: Money): boolean {
  return moneyToDinero(a).greaterThan(moneyToDinero(b));
}

export function lessThanOrEqual(a: Money, b: Money): boolean {
  return moneyToDinero(a).lessThanOrEqual(moneyToDinero(b));
}

export function lessThan(a: Money, b: Money): boolean {
  return moneyToDinero(a).lessThan(moneyToDinero(b));
}

export function equalsTo(a: Money, b: Money): boolean {
  return moneyToDinero(a).equalsTo(moneyToDinero(b));
}

export function addMoneyObjects(...moneys: Money[]): Money {
  let sum: Dinero;
  moneys.forEach((money) => {
    const moneyAsDinero = moneyToDinero(money);
    sum = sum ? sum.add(moneyAsDinero) : moneyAsDinero;
  });

  return toObject(sum);
}

export function calculateSubtotal(price: Money, quantity: number): Money {
  return toObject(moneyToDinero(price).multiply(quantity));
}

export function percentage(price: Money, discount: number): Money {
  return toObject(moneyToDinero(price).percentage(discount)); //TODO: agregar rounding method del supplier
}

export function multiply(a: Money, quantity: number): Money {
  return toObject(moneyToDinero(a).multiply(quantity));
}

export function calculatePromotionTotalFromDisplays(
  products: Omit<ProductInCart, "product">[],
  discount: number
): Money {
  const totalPrice = calculateFinalPrice(products);
  return percentage(totalPrice, discount);
}
