"use client";
import { useContext, useEffect } from "react";
import { UIContext } from "../context";
import { NiloAlert } from "../components/alertMessage";

export const UIWrapper = ({ children }) => {
  const { globalMessage, showGlobalMessage } = useContext(UIContext);

  useEffect(() => {
    if (!globalMessage) return;
    const timer1 = setTimeout(() => showGlobalMessage(null), 4000);
    return () => {
      clearTimeout(timer1);
    };
  }, [globalMessage]);

  const RenderAlert = () => {
    return (
      <>
        {!!globalMessage && (
          <NiloAlert
            message={globalMessage.message}
            type={globalMessage.type}
          />
        )}
      </>
    );
  };

  return (
    <div>
      {children}
      <RenderAlert />
    </div>
  );
};
