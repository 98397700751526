import { FC } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import ReactPaginate from "react-paginate";

interface Props {
  take?: number;
  count: number;
  handlerPage: (page: number) => void;
  initialPage?: number;
  forcePage?: number;
}

export const TAKE_DEFAULT = 8;

export const NiloPagination: FC<Props> = ({
  take = TAKE_DEFAULT,
  count,
  handlerPage,
  initialPage,
  forcePage
}) => {
  const pageCount = count > take ? Math.ceil(count / take) : 1;

  const onChangePage = ({ selected }) => {
    handlerPage(selected + 1);
  };

  const forcePageReRender = !!forcePage && forcePage - 1; //ReactPaginate starts in 0

  return (
    <>
      <div
        className={`items-center gap-4 ${pageCount > 1 ? "flex" : "hidden"}`}
      >
        <ReactPaginate
          forcePage={forcePageReRender}
          className="font-regular flex items-center gap-8 text-xs"
          breakLabel="..."
          onPageChange={onChangePage}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          activeClassName="rounded-lg bg-slate-900 text-white px-3 py-[6px]"
          disabledClassName="[&>*]:!cursor-not-allowed"
          previousLabel={
            <div className="flex items-center font-bold">
              <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
              <span className="pl-2">Anterior</span>
            </div>
          }
          nextLabel={
            <div className="flex items-center font-bold">
              <span className="pr-2">Siguiente</span>
              <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
            </div>
          }
          renderOnZeroPageCount={null}
          disabledLinkClassName="text-slate-400 cursor-none"
          initialPage={initialPage}
        />
      </div>
    </>
  );
};
