import { useEffect } from "react";
import { useField } from "formik";

interface RadioButtonProps {
  name: string;
  options: Array<{ label: string; value: string }>;
  className?: string;
  onChange: (value: string) => void;
}

export const NiloRadioButton: React.FC<RadioButtonProps> = ({
  name,
  options,
  className,
  onChange,
}) => {
  const [field, , helpers] = useField(name);

  useEffect(() => {
    if (!field.value && options.length > 0) helpers.setValue(options[0].value);
  }, [field.value, options, helpers]);

  const handleRadioChange = (value: string) => {
    helpers.setValue(value);
    onChange(value);
  };

  return (
    <div className={className}>
      {options.map((option, index) => (
        <div key={option.value}>
          <input
            key={option.value}
            type="radio"
            id={option.value}
            {...field}
            value={option.value}
            checked={field.value === option.value}
            onChange={() => handleRadioChange(option.value)}
          />
          <label htmlFor={option.value} className="ml-2 hover:cursor-pointer">
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};
