/* eslint-disable prettier/prettier */
"use client";
import { redirect } from "next/navigation";
import { parseCookies } from "nookies";
import { COOKIES } from "@nilo/constants";
import { lang, localesType } from "../constants";

export const redirectTo = (url: string) => {
  redirect(urlWithLocale(url));
};

export const urlWithLocale = (url: string) => {
  const locale = getLocale();
  return `/${locale}/${url}`;
};

export const getLocale = (): localesType => {
  const cookies = parseCookies();
  const locale = cookies[COOKIES.locale] || lang.es;
  return locale as localesType;
};