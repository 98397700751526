"use client";
import { UIState } from ".";

export enum ACTIONS {
  UI_GLOBAL_MESSAGE = "[UI] - global message"
}

export enum MessageType {
  ERROR = "error",
  WARNNING = "warnning",
  SUCCESS = "success"
}

export interface MessagePayload {
  message: string;
  type?: MessageType;
}

type UIActionType = {
  type: ACTIONS.UI_GLOBAL_MESSAGE;
  payload: MessagePayload;
};

export const uiReducer = (state: UIState, action: UIActionType): UIState => {
  switch (action.type) {
    case ACTIONS.UI_GLOBAL_MESSAGE:
      return {
        ...state,
        globalMessage: action.payload
      };

    default:
      return state;
  }
};
