import { FC, ReactElement } from "react";
import Image from "next/image";

interface Props {
  description?: ReactElement | string;
  size?: "xs" | "sm" | "md" | "lg";
}

export const EmptyState: FC<Props> = ({ description, size }) => {
  const mapSizes = {
    ["xs"]: { height: 100, width: 80 },
    ["sm"]: { height: 160, width: 140 },
    ["md"]: { height: 180, width: 227 },
    ["lg"]: { height: 250, width: 300 }
  };

  return (
    <div className="mt-10 flex flex-col items-center justify-center">
      <Image
        src="/img/empty.png"
        height={mapSizes[size || "md"].height}
        width={mapSizes[size || "md"].width}
        alt="No data"
      />
      <div className="font-regular mt-10 whitespace-pre text-wrap text-center text-sm text-slate-900">
        {description}
      </div>
    </div>
  );
};
