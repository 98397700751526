import { Card, CardBody } from "@material-tailwind/react";
import { NiloSpinner } from "../spinner";

interface TableWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
}

export function NiloTableWrapper({ children, isLoading }: TableWrapperProps) {
  return (
    <Card className="h-full w-full overflow-auto">
      <CardBody className="bg-slate-50 p-0">
        {isLoading ? (
          <div className="flex min-h-[400px] items-center justify-center">
            <NiloSpinner />
          </div>
        ) : (
          <table className="w-full table-auto text-left">{children}</table>
        )}
      </CardBody>
    </Card>
  );
}
