/* eslint-disable @typescript-eslint/no-explicit-any */
import { NumericFormat } from "react-number-format";
import { FC } from "react";

interface Props {
  simbol?: string;
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  classInput?: string;
  [x: string]: any;
  onChange?: (value: number) => void;
  value: number;
}

export const NiloInputMoney: FC<Props> = ({
  classInput,
  disabled,
  label,
  onChange,
  simbol,
  value,
  ...props
}) => {
  const onValueChange = (values) => {
    onChange && onChange(values.floatValue || "");
  };

  return (
    <div>
      {!!label && (
        <label
          className="mb-2 block text-xs text-slate-900"
          htmlFor={props.name}
        >
          {label}
        </label>
      )}
      <NumericFormat
        id={props.id}
        name={props.name}
        className={`w-full rounded-lg bg-slate-100 p-3 text-sm placeholder-slate-400 focus:outline-none ${
          disabled ? "pointer-events-none text-slate-400" : "text-slate-900"
        } autofill:bg-white ${classInput} `}
        {...props}
        placeholder={props.placeholder}
        onValueChange={onValueChange}
        thousandSeparator={true}
        prefix={simbol || "$"}
        decimalScale={2}
        fixedDecimalScale={true}
        allowNegative={false}
        value={value}
      />
    </div>
  );
};
