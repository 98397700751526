import { locales } from "./constants";
import createIntlMiddleware from "next-intl/middleware";
import { NextRequest, NextResponse } from "next/server";
import { getLocale } from "./utils";
import { COOKIES } from "@nilo/constants";

const i18nMiddleware = createIntlMiddleware({
  locales,
  defaultLocale: getLocale()
});

const hasLocale = (pathname: string): boolean =>
  locales.some(
    (locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`
  );

export const withMiddlewarei18n = (req: NextRequest): NextResponse => {
  const { pathname } = req.nextUrl;
  const locale = req.cookies.get(COOKIES.locale)?.value || getLocale();
  if (!hasLocale(pathname))
    req.nextUrl.pathname = `/${locale}${pathname}`;

  return i18nMiddleware(req);
};


