import { Spinner } from "@material-tailwind/react";

interface Props {
  message?: string;
}

export const NiloSpinner = ({ message }: Props) => {
  return (
    <div
      className="flex h-full items-center justify-center"
      data-testid="spinner"
    >
      <div className="text-x12 flex flex-col items-center text-slate-500">
        <Spinner className="mb-10 h-8 w-8" color="blue" />
        {message}
      </div>
    </div>
  );
};
