"use client";

import React from "react";
import { NextIntlClientProvider } from "next-intl";
import { localesType, timeZone } from "../constants";
import { getMessages } from "../messages";

interface Props {
  children: React.ReactNode;
  locale: localesType;
}

export const I18nProvider = ({ children, locale }: Props) => {
  const messages = getMessages(locale);

  return (
    <NextIntlClientProvider
      messages={messages}
      locale={locale}
      timeZone={timeZone}
    >
      {children}
    </NextIntlClientProvider>
  );
};
