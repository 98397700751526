"use client";
import { createContext } from "react";
import { MessagePayload } from "./reducer";

export interface UIContextProps {
  globalMessage: MessagePayload;
  showGlobalMessage: (payload: MessagePayload) => void;
  setSearch: (value: string) => void;
  search: string;
  clearSearch: () => void;
}

export const UIContext = createContext({} as UIContextProps);
