"use client";
import { FC, useReducer, useState } from "react";
import { ACTIONS, MessagePayload, UIContext, uiReducer } from ".";

export interface UIState {
  globalMessage: MessagePayload | null;
  searchInputValue: string;
  search: string;
}

const UI_INITIAL_STATE: UIState = {
  globalMessage: null,
  searchInputValue: "",
  search: ""
};

export const UIProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, UI_INITIAL_STATE);
  const [search, setSearch] = useState<string>("");

  const clearSearch = () => {
    setSearch("");
  };

  const showGlobalMessage = (payload: MessagePayload) => {
    dispatch({ type: ACTIONS.UI_GLOBAL_MESSAGE, payload });
  };

  return (
    <UIContext.Provider
      value={{
        ...state,
        showGlobalMessage,
        setSearch,
        search,
        clearSearch
      }}
    >
      {children}
    </UIContext.Provider>
  );
};
