import { Tabs, TabsHeader, Tab } from "@material-tailwind/react";
import { useState } from "react";

interface Props {
  className: string;
  onChange: (tab) => void;
  tabsHead: {
    label: string;
    value: string;
  }[];
}

export const NiloTabs = ({ className, onChange, tabsHead }: Props) => {
  const [state, setState] = useState({
    activeTab: tabsHead[0].value
  });

  const handlerTab = (tab) => {
    setState({ ...state, activeTab: tab });
    onChange(tab);
  };

  return (
    <div>
      <Tabs value={state.activeTab}>
        <TabsHeader
          className="rounded-none border-b border-slate-200 bg-transparent p-0 "
          indicatorProps={{
            className:
              "bg-transparent border-b-2 border-blue-500 shadow-none rounded-none"
          }}
        >
          {tabsHead.map(({ label, value }) => (
            <Tab
              key={value}
              className={`${className} py-4 text-xs ${
                state.activeTab === value ? "font-bold text-blue-600" : ""
              }`}
              value={value}
              onClick={() => handlerTab(value)}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
    </div>
  );
};
