import { useState } from "react";
import {
  Popover,
  PopoverHandler,
  PopoverContent
} from "@material-tailwind/react";
import { format } from "date-fns";
import { Datepicker } from "./datepicker";
import { DateFormat } from "@nilo/constants";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface Props {
  name: string;
  label?: string;
  palceholder?: string;
  onDateChange: (date: Date) => void;
}

export const NiloDatePicker = ({
  label,
  name,
  onDateChange,
  palceholder
}: Props) => {
  const [date, setDate] = useState<Date>();
  const [openPopover, setOpenPopover] = useState(false);

  const handleDateChange = (selectedDate: Date) => {
    setDate(selectedDate);
    onDateChange(selectedDate);
    setOpenPopover(false);
  };

  const Label = () => {
    return (
      <>
        {" "}
        {!!label && (
          <label className="mb-2 block text-xs text-slate-900" htmlFor={name}>
            {label}
          </label>
        )}
      </>
    );
  };

  const CloseIcon = () => {
    return (
      <>
        {date && (
          <XMarkIcon
            className="absolute right-2 top-1/2 h-4 -translate-y-1/2 transform cursor-pointer text-slate-400"
            onClick={(event) => {
              event.stopPropagation();
              handleDateChange(undefined);
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Label />
      <Popover placement="bottom" open={openPopover} handler={setOpenPopover}>
        <PopoverHandler>
          <div className="relative">
            <input
              className="w-full rounded-lg bg-slate-100 p-3 pr-6 text-sm placeholder-slate-400 focus:outline-none"
              name={name}
              placeholder={palceholder}
              onChange={() => null}
              value={date ? format(date, DateFormat) : ""}
            />
            <CloseIcon />
          </div>
        </PopoverHandler>
        <PopoverContent className="z-full">
          <Datepicker date={date} handleDateChange={handleDateChange} />
        </PopoverContent>
      </Popover>
    </>
  );
};
