import React from "react";
import { useField } from "formik";

interface SelectOption {
  label: string;
  value: string;
}

interface SelectProps {
  name: string;
  label?: string;
  options: SelectOption[];
  className?: string;
  onChange?: (value: string) => void;
}

export const NiloSelect: React.FC<SelectProps> = ({
  className,
  label,
  name,
  onChange,
  options
}) => {
  const [field, , helpers] = useField(name);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    helpers.setValue(selectedValue);
    !!onChange && onChange(selectedValue);
  };

  return (
    <div className={className}>
      {label && (
        <label
          className="text-sm font-bold tracking-wide text-gray-700"
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <select
        {...field}
        onChange={handleSelectChange}
        className="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:ring"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
