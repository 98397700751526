import React from "react";
import Link from "next/link";
import { urlWithLocale } from "@i18n";

interface Props {
  url: string;
  children: React.ReactNode;
}
export const NiloLink = ({ children, url }: Props) => {
  return (
    <Link className="outline-none" href={urlWithLocale(url)}>
      {children}
    </Link>
  );
};
