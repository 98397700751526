export enum lang {
    es = "es",
    en = "en",
    esHN = "es-HN",
    esAR = "es-AR"
}

export const locales = ["es", "en", "es-HN", "es-AR"];

export const localesRegex = locales.join("|");

export type localesType = lang.es | lang.en | lang.esHN | lang.esAR;

export const defaultLocale = lang.es;

export const timeZone = "Central Standard Time";
