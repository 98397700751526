function isObject(item): item is Record<string, unknown> {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function deepMerge<T, U>(target: T, source: U): T & U {
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                deepMerge(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }
    return target as T & U;
}